<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Gateway Application Info</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item">Charging & Billing</li>
              <li class="breadcrumb-item">API Gateway</li>
              <li class="breadcrumb-item active">Application</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card" id="appInfo-add">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="handleSubmission()" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link
                class="btn btn-info"
                :to="{ name: 'ApiGateWayApplicationList' }"
              >
                <i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="name" class="control-label">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                v-bind:class="{ 'has-error': errors.has('name') }"
                v-validate="'required'"
                v-model="appInfo.name"
                class="form-control name"
                placeholder="Name"
              />
              <div class="help text-danger" v-show="errors.has('name')">
                {{ errors.first("name") }}
              </div>
            </div>
            <div class="form-group required">
              <label class="control-label">Organization</label>
              <multiselect
                name="Organization"
                v-model="appInfo.organization_id"
                :options="organizations.map((org) => org.id)"
                :custom-label="(opt) => organizations.find((x) => x.id === opt).org_name"
                placeholder="Pick Organization"
                :searchable="true"
                :multiple="false"
                v-bind:class="{ 'has-error': errors.has('Organization') }"
                v-validate="'required'"
                class="organization"
              ></multiselect>
              <div class="help text-danger" v-show="errors.has('Organization')">
                {{ errors.first("Organization") }}
              </div>
            </div>
            <div class="form-group required">
              <label for="status" class="control-label">Status</label>
              <select
                class="form-control"
                name="status"
                v-bind:class="{ 'has-error': errors.has('status') }"
                v-validate="'required'"
                id="status"
                v-model="appInfo.status"
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              <div class="help text-danger" v-show="errors.has('status')">
                {{ errors.first("status") }}
              </div>
            </div>
            <div class="offset-0 col-12" v-if="is_edit">
              <h4 class="site-heading my-3"><span>Settings</span></h4>
              <table class="table">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Description</th>
                </tr>
                </thead>
                <tbody class="has-many-student_detail-forms">
                <tr class="has-many-student_detail-form fields-group" v-for="(settings, i) in appInfo.settings">
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="settings.name" placeholder="Institute Name">
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="settings.value" placeholder="Passing Year">
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="settings.description" placeholder="Degree Name">
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" v-if="!is_edit" class="btn btn-primary">
              Submit
            </button>
            <button type="submit" v-if="is_edit" class="btn btn-primary">
              Update
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import MasterLayout from "@/components/layouts/MasterLayoutComponent";
import {CommonAPI} from "@/services/api";
import CBSApiGateWayApplicationInfo from "@/services/api/cbs-api-gateway/CBSApiGateWayApplicationInfo";
import {mapGetters} from "vuex";


export default {
  name: "ApiGatewayApplicationForm",
  components: {},
  data: () => ({
    appInfo: {},
    organizations: [],
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
  }),
  computed: {
    ...mapGetters(["profile"])
  },
  methods: {
    handleSubmission() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.is_edit) {
            this.appInfo.updated_by = this.profile.id;
            CBSApiGateWayApplicationInfo.update(this.params.id, this.appInfo)
              .then((response) => {
                this.$router.push({name: "ApiGateWayApplicationList"});
                this.$toaster.success(response.message);
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data);
              });
          } else {
            this.appInfo.app_ref_id = "NA";
            this.appInfo.token = "NA";
            this.appInfo.settings = "NA";
            this.appInfo.created_by = this.profile.id;
            // console.log(this.profile);
            CBSApiGateWayApplicationInfo.store(this.appInfo)
              .then((response) => {
                this.$router.push({name: "ApiGateWayApplicationList"});
                this.$toaster.success(response.message);
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data);
              });
          }
        }
      });
    },

    async appInfoShow(id) {
      await CBSApiGateWayApplicationInfo.show(id).then((appInfo) => {
        if (
          !_.isEmpty(appInfo.organization_id) &&
          typeof appInfo.organization_id !== undefined
        ) {
          this.organizations[0] = appInfo.organization_id;
        }
        this.appInfo = appInfo;
      });
    },
    async initialLoad() {
      this.is_loading = true;
      this.params = this.$route.params;
      await this.getOrganizations();
      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        console.log('here', this.params.id);
        await this.appInfoShow(this.params.id);
      }

      console.log(this.organizations)
      this.is_loading = false;
    },
    async getOrganizations() {
      await CommonAPI.getAllOrganizations().then((organizations) => {
        console.log("load org", organizations);
        this.organizations = organizations;
      });
    },
  },
  mounted() {
    this.initialLoad();
  },
  created() {
    this.$emit("update:layout", MasterLayout);
  },
};
</script>
